import {action, computed, observable, runInAction, toJS} from "mobx"
import {Gate} from "./lib/gate/Gate"
import {Action, History, Location} from "history"
import {User, Visitor} from "./lib/gate/interfaces"
import {Form, FormID} from "./interfaces"
import {route} from "./lib/functions/route"
import {DataStore} from "./data/DataStore"
import {EventDispatcher} from "./lib/gate/EventDispatcher"
import Swal from "sweetalert2"
import _ from "lodash"
import {
    removeJsonDataForms,
    currentSizeForm,
    CALIFORNIA_FORMS_PDF,
    MANHATTAN_FORMS_PDF,
    ENROLLMENT_AGREEMENT,
    SCHOOLPERFORMANCE_FACT,
    OPT_OUTS,
    YesNo,
    APPLICATION_CAREER_INFORMATION,
    getPartsByDelimiter
} from "./helper/Methods"

const ROLE = {
    staff: "staff",
    student: "student"
}

const forms: Form[] = [
    {
        form_id: "application_career_information",
        title: "APPLICATION / CAREER INFORMATION",
        type: "web",
        enabled: true
    },
    {
        form_id: "enrollment_application",
        title: "Enrollment application",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "academic_adjustment_form",
        title: "Academic Adjustment Form",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "application_upload_disability_doc",
        title: "Upload Supporting Doc",
        type: "web",
        enabled: true
    },
    {
        form_id: "application_ready_to_enroll",
        title: "Ready to enroll",
        type: "web",
        enabled: true
    },
    {
        form_id: "opt_outs",
        title: "Opt outs form",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "enrollment_agreement",
        title: "Enrollment agreement",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "arbitration_agreement",
        title: "Arbitration agreement",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "school_performance_fact",
        title: "School Performance Fact",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "distance_education_disclosure",
        title: "Distance Education Disclosure",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "freelance_selfemployed_disclosure",
        title: "Freelance/Self-Employed Disclosure",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "checklist_page",
        title: "Checklist Page",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "criminal_disclosure",
        title: "Requirements Disclosure",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "ferpa_release_form",
        title: "FERPA Release Form",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "overtime_disclosure",
        title: "Overtime Disclosure",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "application_upload_docs",
        title: "Application Upload docs",
        type: "web",
        enabled: true
    },
    {
        form_id: "submission",
        title: "submission",
        type: "web",
        enabled: true
    }
]

const tabs = [
    {
        id: "1",
        title: "Career Information",
        tab_id: "career_information",
        tabletTitle: "Career Info",
        items: [
            {
                label: "Program Selection",
                value: "application_career_information"
            }
        ],
        disabled: false,
        removed: false
    },
    // {
    //     id: "2",
    //     title: "Disabilities",
    //     tab_id: "disabilites",
    //     tabletTitle: "Career Info",
    //     items: [
    //         {
    //             label: "Academic Adjustment Form",
    //             value: "academic_adjustment_form"
    //         },
    //         {
    //             label: "Upload Supporting Doc",
    //             value: "application_upload_disability_doc"
    //         }
    //     ],
    //     disabled: false,
    //     removed: false
    // },
    // {
    //     id: "3",
    //     tab_id: "ready_to_enroll",
    //     title: "Ready to enroll",
    //     tabletTitle: "Ready to enroll",
    //     items: [{value: "application_ready_to_enroll", label: "Ready to enroll"}],
    //     disabled: true,
    //     removed: false
    // },
    {
        id: "4",
        title: "Forms",
        tab_id: "forms",
        tabletTitle: "Forms",
        items: [
            {value: "enrollment_application", label: "Enrollment Application"},
            {
                label: "Academic Adjustment Form",
                value: "academic_adjustment_form"
            },
            {
                label: "Upload Supporting Doc",
                value: "application_upload_disability_doc"
            },
            {value: "application_ready_to_enroll", label: "Ready to enroll"},
            {value: "opt_outs", label: "Opt Outs Form"},
            {value: "enrollment_agreement", label: "Enrollment Agreement"},
            {value: "arbitration_agreement", label: "Arbitration Agreement"},
            {value: "school_performance_fact", label: "School Performance Fact"},
            {value: "distance_education_disclosure", label: "Distance Education Disclosure"},
            {
                value: "freelance_selfemployed_disclosure",
                label: "Freelance/Self-Employed Disclosure"
            },
            {value: "checklist_page", label: "Checklist Page"},
            {value: "criminal_disclosure", label: "Requirements Disclosure"},
            {value: "ferpa_release_form", label: "FERPA Release Form"},
            {value: "overtime_disclosure", label: "Overtime Disclosure"}
        ],
        disabled: false,
        removed: false
    },
    {
        id: "5",
        tab_id: "upload_docs",
        title: "Upload docs",
        tabletTitle: "Upload docs",
        items: [
            {
                label: "Documents",
                value: "application_upload_docs"
            }
        ],
        disabled: false,
        removed: false
    },
    {
        id: "6",
        tab_id: "submission",
        title: "Submission",
        tabletTitle: "Submission",
        items: [
            {
                label: "Submission",
                value: "submission"
            }
        ],
        disabled: false,
        removed: false
    }
]

export class Model {
    public dispatcher = new EventDispatcher()
    public dispatcher2 = new EventDispatcher()
    private validateFunctions: Function[] = []

    @observable
    public documentClone: UserDocument = {json_data: {}}

    @observable
    public childErrors: any

    @observable
    public document: UserDocument = {json_data: {}}

    @observable
    public signaturePopup: {
        show: boolean
        signature_key
        mode: "signature" | "initials" | "parent"
    } = {
        show: false,
        signature_key: "",
        mode: "signature"
    }

    @observable
    private form_id: FormID = "application_career_information"

    @observable
    public iframeLoaded = false

    @observable
    public location: Location

    @observable
    public path: string

    @observable
    public visitor: Visitor

    @observable
    public user: User | undefined

    @observable
    public appSettings: any

    @observable
    public popup = {visible: false, message: ""}

    public childBridge: any

    @observable
    public is_form_submitted = false

    private parentBridge: any

    @observable
    public careerInfo: any

    @observable
    public campusesInfo: any

    @observable
    public campusesLinksInfo: any

    public updatePdfView() {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-pdf")
            }

            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
    }

    public async refreshPdfView() {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error(
                    "No childBridge refreshPdfView! Please check bridge set in admissions-bellus-pdf"
                )
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge refreshPdfView:", this.childBridge)

        if (this.form.type === "web") {
            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        // const [_, no] = ["yes", "no"]
        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form

        console.log("my current JSON DATA from refreshPdfView:", toJS(json_data))
        const id = this.user.doc.id
        try {
            let r: any = await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })
            if (r.success) {
                const {
                    data: {
                        json_data: {auth_choice}
                    }
                } = r
                this.childBridge.forceReload()
                // if (auth_choice && auth_choice === no) {
                // }
            }
            this.dispatcher.dispatch()
            this.dispatcher2.dispatch()
            return r.success
        } catch (error) {
            console.log(`Something happens while save refreshPdfView: ${error}`)
        }
    }

    constructor(public gate: Gate, private history: History, public dataStore: DataStore) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH") // execute one time
        // @ts-ignore
        this.parentBridge = window.parent.bridge
    }

    public enableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public disableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    public disableTabs(form_id: any) {
        for (let tab of tabs) {
            if (tab.tab_id === form_id) {
                tab.disabled = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public enableTabs(form_id: any) {
        for (let tab of tabs) {
            if (tab.tab_id === form_id) {
                tab.disabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    public removeTabs(form_id: any) {
        for (let tab of tabs) {
            if (tab.tab_id === form_id) {
                tab.removed = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public includeTabs(form_id: any) {
        for (let tab of tabs) {
            if (tab.tab_id === form_id) {
                tab.removed = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    @action
    public setFormId(id: FormID) {
        this.childErrors = undefined
        console.log("SET FORM ID=", id)
        // this.form_id = id
        this.goTo(`/form/${id}?token=${this.gate.getToken()}&user_id=${this.user?.id}`)
        this.iframeLoaded = false
    }

    @computed
    public get form(): Form {
        return forms.find((el) => {
            return el.form_id === this.form_id
        })
    }

    public goTo(url: string) {
        console.log(`GO TO URL:${url}`)
        this.history.push(url)
    }

    public getTabs(): any {
        return tabs.filter(({removed}) => removed !== true) // eye
    }

    public getForms(): Form[] {
        return forms
    }

    public getFormId(): FormID {
        return this.form_id
    }

    public get enabledItems(): FormID[] {
        let arr = forms.filter((el) => {
            return el.enabled
        })

        return arr.map((el: Form) => {
            return el.form_id
        })
    }

    public getPreviousFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }
        if (index === 0) {
            return null
        }

        for (let i = index - 1; i >= 0; i--) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    public getNextFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i] && forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }

        if (index + 1 === forms.length) {
            return null
        }

        for (let i = index + 1; i < forms.length; i++) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
        console.log("ON HISTORY Change=", this.path)
        if (route("/form/:id(/)", this.path)) {
            // regex
            const {id} = route("/form/:id(/)*", this.path)
            this.form_id = id
        }
    }

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    @action
    public alert(message: string) {
        this.popup.visible = true
        this.popup.message = message
    }

    public get isStaff(): boolean {
        if (!this.visitor) {
            return false
        }
        const role = this.visitor.role
        if (role === "staff" || role === "admin") {
            return true
        }

        return false
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public async loadUser(user_id: number): Promise<void> {
        let r = await this.gate.request(`/users/${user_id}/get`)
        this.user = r.data
    }

    public async loadCareerInformation(): Promise<void> {
        const {data} = await this.gate.request(`/careers`)
        this.careerInfo = data
    }

    public async loadCampuses(): Promise<void> {
        const {data} = await this.gate.request(`/campuses`)
        this.campusesInfo = data
    }

    public async loadCampusesLinks(): Promise<void> {
        const {data} = await this.gate.request(`/campuses/programs/links`)
        this.campusesLinksInfo = data
    }

    public getHistory(): History {
        return this.history
    }

    public async submit(): Promise<void> {
        let json_data
        json_data = this.document.json_data
        const id = this.user.doc.id
        try {
            const [, , , {id: manhattanCampusId}] = this.campusesInfo
            const [campusId, ,] = getPartsByDelimiter(json_data.campus)
            // console.log("@@@@ campusCondition:", {
            //     manhattanCampusId,
            //     campusId
            // })
            if (+campusId !== manhattanCampusId) {
                json_data.isSubmitted =
                    CALIFORNIA_FORMS_PDF.length ===
                    currentSizeForm(json_data.forms || [], CALIFORNIA_FORMS_PDF)
            } else {
                json_data.isSubmitted =
                    MANHATTAN_FORMS_PDF.length ===
                    currentSizeForm(json_data.forms || [], MANHATTAN_FORMS_PDF)
            }
            json_data.currentSavedFormId = this.form
            const responseSave = await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })

            if (responseSave.success) {
                const responseSubmit = await this.gate.request(`/users/${this.user.id}/submit`)
                if (responseSubmit.success) {
                    await Swal.fire({
                        icon: "success",
                        title: "Application submitted",
                        text:
                            "Thank you for submit your application. An advisor will contact you soon."
                    })
                } else {
                    delete json_data.isSubmitted
                }
            }
        } catch (error) {}
        runInAction(() => {
            this.is_form_submitted = true
        })
    }

    public async getCareerInformation(json_data) {
        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        // const submittedDoc = await this.getSubmittedDocByUserId() //remove this for regenerate
        // if (
        //     submittedDoc.data.json_data.campus !== json_data.campus ||
        //     submittedDoc.data.json_data.program !== json_data.program ||
        //     submittedDoc.data.json_data.advisor !== json_data.advisor ||
        //     submittedDoc.data.json_data.start_date !== json_data.start_date ||
        //     submittedDoc.data.json_data.mua_type !== json_data.mua_type
        // ) {
        // }
        if (APPLICATION_CAREER_INFORMATION === this.form_id) {
            try {
                const {
                    tuition_data,
                    schedule_data,
                    spf_data,
                    opt_out_data,
                    condition_text_data
                } = await this.generateTuituonByCampusAndProgram(json_data)
                json_data.extra.tuitionInfo = tuition_data
                json_data.extra.optOutInfo = opt_out_data
                json_data.extra.scheduleInfo = schedule_data
                json_data.extra.spfInfo = spf_data
                json_data.extra.conditionTextInfo = condition_text_data
                const currentForms = json_data.forms
                json_data.forms = removeJsonDataForms(currentForms || [])
                const [, , , manhattanCampusId] = this.campusesInfo
                if (json_data.extra.campus.id === +manhattanCampusId) {
                    delete json_data.forms.school_performance_fact
                }
                delete json_data.uploads
                delete json_data.uploadDisabilities
            } catch (error) {
                console.log(`Something happens while get tuitions: ${error}`)
            }
        }
    }

    public async showDisabilityPopup({id, value}): Promise<any> {
        let json_data
        json_data = this.document.json_data
        if (!json_data) {
            return false
        }
        json_data[id] = value
        if (json_data.disabilites === YesNo.yes) {
            return await Swal.fire({
                icon: "warning",
                title: "Student with disabilities",
                allowOutsideClick: false,
                text: `By selecting “Yes” You are stating you have a “Documented Disability” that requires Academic Adjustment review by the Bellus Academy review committee.`,
                showCancelButton: true,
                confirmButtonText: `Confirm`
            })
        }
    }

    public async saveEnrollmentApplication(): Promise<boolean> {
        let json_data
        const docId = this.user.doc.id
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-bellus-pdf")
            }
            // const {isValid, errors} = await this.childBridge.validate()
            // if (!isValid) {
            //     this.childErrors = errors
            //     return false
            // }
            if (!(await this.childBridge.validate())) {
                await Swal.fire({
                    icon: "error",
                    title: "There are some errors.",
                    text: "Please take a look."
                })
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }
        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        json_data.currentSavedFormId = this.form
        try {
            let r: any = await this.gate.request(`/users/${docId}/enrollment-application/edit`, {
                data: {json_data}
            })
            if (r.success) {
                const {
                    data: {
                        json_data: {disabilitySelected, isApplicationSubmitted}
                    }
                } = r

                json_data.disabilitySelected = disabilitySelected
                json_data.isApplicationSubmitted = isApplicationSubmitted
            }
            this.dispatcher.dispatch()
            this.dispatcher2.dispatch()
            return r.success
        } catch (error) {
            console.log("Something happened on save enrollment application.", error)
        }
    }

    public verifyJsonDataChanges() {
        const current_json_data = this.document.json_data
        // console.log('@@@@@@@@ current_json_data', toJS(current_json_data))
        // console.log('@@@@@@@@ this.documentClone', toJS(this.documentClone.json_data))
        // this.dispatcher2.dispatch()
        return _.isEqual(current_json_data, this.documentClone.json_data)
    }

    public restoreDocumentClone() {
        this.document = _.cloneDeep({...this.documentClone})
        this.dispatcher2.dispatch()
    }

    public async saveAgreement(): Promise<boolean> {
        let json_data
        const id = this.user.doc.id
        if (this.form.type === "web") {
            // if (!this.validate()) {
            //     return false
            // }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }
        json_data.accepted = true
        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        if (r.success) {
            const {
                data: {
                    json_data: {accepted}
                }
            } = r

            json_data.accepted = accepted
        } else {
            if (json_data.accepted) {
                delete json_data.accepted
            }
        }

        return r.success
    }

    public async saveCareerInformation(): Promise<boolean> {
        let json_data
        if (this.form.type === "web") {
            if (!this.validate()) {
                await Swal.fire({
                    icon: "error",
                    title: "There are some errors.",
                    text: "Please take a look."
                })
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        console.log("Save Career Information form_id:", this.form_id)
        try {
            const id = this.user.doc.id
            const currentForms = json_data.forms
            const [, , , manhattanCampusId] = this.campusesInfo
            json_data.forms = removeJsonDataForms(currentForms || [])
            json_data.currentSavedFormId = this.form
            if (json_data.extra.campus.id === +manhattanCampusId) {
                delete json_data.forms.school_performance_fact
            }
            let r: any = await this.gate.request(`/users/${id}/career/edit`, {
                data: {json_data}
            })
            if (r.success) {
                const {
                    data: {
                        json_data: {
                            hasCareerInformation,
                            extra: {
                                campus,
                                program,
                                conditionTextInfo,
                                optOutInfo,
                                scheduleInfo,
                                spfInfo,
                                tuitionInfo
                            }
                        }
                    }
                } = r
                json_data.hasCareerInformation = hasCareerInformation
                this.writeObjectParamsInJsonData(json_data, campus)
                json_data.extra.tuitionInfo = tuitionInfo
                json_data.extra.conditionTextInfo = conditionTextInfo
                json_data.extra.optOutInfo = optOutInfo
                json_data.extra.scheduleInfo = scheduleInfo
                json_data.extra.spfInfo = spfInfo
                json_data.extra.program = program.links
                // delete json_data.uploads
                // delete json_data.uploadDisabilities
                this.dispatcher.dispatch()
                this.dispatcher2.dispatch()
            }
            return r.success
        } catch (error) {
            console.log("Internal server error while saving career information.", error)
            return false
        }
    }

    public writeObjectParamsInJsonData(json_data, campusResponse): void {
        json_data.extra.campus.region_id = campusResponse.region_id
        json_data.extra.campus.name = campusResponse.name
        json_data.extra.campus.code = campusResponse.code
        json_data.extra.campus.address = campusResponse.address
        json_data.extra.campus.state = campusResponse.state
        json_data.extra.campus.phone = campusResponse.phone
        json_data.extra.campus.school_code = campusResponse.school_code
        json_data.extra.campus.region = campusResponse.region
    }

    public async save(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-bellus-pdf")
            }
            // const {isValid, errors} = await this.childBridge.validate()
            // if (!isValid) {
            //     this.childErrors = errors
            //     return false
            // }
            if (!(await this.childBridge.validate())) {
                await Swal.fire({
                    icon: "error",
                    title: "There are some errors.",
                    text: "Please take a look."
                })
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        json_data.currentSavedFormId = this.form
        console.log("my current JSON DATA:", toJS(json_data))
        const bellusCost = "bellusCost"
        if (OPT_OUTS === this.form_id && json_data.role === ROLE.student) {
            const studentOptOutArray = json_data.extra.optOutInfo.optout.opt
            const currentOptAmmountYes = studentOptOutArray.filter(
                ({optOut}) => optOut === YesNo.yes
            )

            const totalOptOutCost = this.summarizeCosts(currentOptAmmountYes, bellusCost)

            json_data.extra.tuitionInfo.tuition.opt_out_total = totalOptOutCost

            if (totalOptOutCost > 0) {
                json_data.extra.tuitionInfo.tuition.purchase = YesNo.yes
            } else {
                json_data.extra.tuitionInfo.tuition.purchase = YesNo.no
            }
            // if (
            //     json_data.extra.tuitionInfo.tuition.purchase &&
            //     json_data.extra.tuitionInfo.tuition.purchase === YES
            // ) {
            //     json_data.extra.tuitionInfo.tuition.opt_out_total = totalOptOutCost
            // } else {
            //     json_data.extra.tuitionInfo.tuition.opt_out_total = 0
            // }
            json_data.extra.tuitionInfo.tuition.current_opt_out_total = totalOptOutCost
        } else {
            // todo admin option opt-out
        }
        console.log("Saving form:", this.form_id)

        const id = this.user.doc.id

        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        // this.documentClone = {...r.data.json_data}
        // this.documentClone = _.cloneDeep({...r.data})
        return r.success
    }

    public async updateDocumentUpload(): Promise<boolean> {
        let json_data
        json_data = this.document.json_data
        if (!json_data) {
            return false
        }
        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        json_data.currentSavedFormId = this.form
        try {
            const id = this.user.doc.id

            let r: any = await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })
            return r.success
        } catch (error) {
            console.log("Internal server error while saving the doc.", error)
            return error
        }
    }

    public async saveAndContinueWithReadyToEnroll(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-bellus-pdf")
            }
            if (!(await this.childBridge.validate())) {
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        json_data.currentSavedFormId = this.form
        const docId = this.user.doc.id
        try {
            let r: any = await this.gate.request(`/users/${docId}/disability/submit`, {
                data: {json_data}
            })
            if (r.success) {
                const {
                    data: {
                        doc: {
                            json_data: {disabilitySubmitted}
                        }
                    }
                } = r
                if (!json_data.disabilitySubmitted) {
                    await Swal.fire({
                        icon: "success",
                        title: `Thank you to complete the disability documentation section`,
                        html: `
                        <p>
                        Your documents are under review by Bellus Academy committee. An advisor will be in contact with you soon, and share the desicion with you.
                        </p>
                        `,
                        willClose: async () => {
                            json_data.disabilitySubmitted = disabilitySubmitted
                            this.dispatcher.dispatch()
                            this.dispatcher2.dispatch()
                            const form_id: FormID = this.getNextFormId()
                            if (form_id) {
                                this.setFormId(form_id)
                            }
                        }
                    })
                }
            }
        } catch (error) {
            delete json_data.disabilitySubmitted
            console.log("Something happened on save submit disability.", error)
        }
    }

    public async checkCampusCode() {
        let json_data
        json_data = this.document.json_data
        let {success, data: dataResponse} = await this.gate.request(`/check/${this.user.id}`, {
            data: {json_data}
        })

        if (success) {
            await Swal.fire({
                icon: "success",
                title: `Valid campus code`,
                text: `Thank you! You may now proceed to the next section.`
            })
            json_data.isValidCampusCode = dataResponse.json_data.isValidCampusCode
            const isSuccess = await this.saveAndContinueWithProcess()
            // console.log("aaaaaaaaa", toJS(isSuccess))
            if (isSuccess) {
                const form_id: FormID = this.getNextFormId()
                this.setFormId(form_id)
            }
        } else {
            await Swal.fire({
                icon: "error",
                title: `Invalid campus code`,
                text: `You may have an invalid campus code. Please Try again.`
            })
        }
        return success
    }

    public async saveAndContinueWithProcess(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error(
                    "No childBridge! Please check bridge set in admissions-tspanampa-pdf"
                )
            }
            if (!(await this.childBridge.validate())) {
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        try {
            const docId = this.user.doc.id
            let r: any = await this.gate.request(`/users/continue/${docId}/edit`, {
                data: {json_data}
            })
            if (r.success) {
                json_data.hasCampusCode = r.data.json_data.hasCampusCode
                this.dispatcher2.dispatch()
            }
            return r.success
        } catch (error) {
            console.log("Internal server error while confirm code the doc.", error)
            // delete json_data.hasCampusCode
            return error
        }
    }

    private summarizeCosts(dataMatrix: Array<any>, keyField: string): number {
        const totalObject = dataMatrix.reduce(
            (acumulator, currentValue) => ({
                [keyField]: acumulator[keyField] + currentValue[keyField]
            }),
            {[keyField]: 0}
        )
        const {[keyField]: total} = totalObject
        return total
    }
    private async updateTuition(jsonData) {
        const {
            extra: {
                tuitionInfo: {id, tuition}
            }
        } = jsonData
        const tuitionResponse = await this.gate.request(`/tuitions/${id}/edit`, {data: tuition})
        const {
            data: {tuition_data}
        } = tuitionResponse
        return tuition_data
    }

    private async updateSPFTable(jsonData) {
        const {
            extra: {
                spfInfo: {id, spf: spfJsonData}
            }
        } = jsonData
        const spfResponse = await this.gate.request(`/spfs/${id}/edit`, {data: spfJsonData})
        const {data} = spfResponse
        return data
    }

    private async generateTuituonByCampusAndProgram(jsonData) {
        const {
            extra: {
                campus: {id: campusId},
                program: {id: programId}
            }
        } = jsonData
        const careerResponse = await this.gate.request(
            `/careers/campuses/${campusId}/programs/${programId}/get`,
            {data: jsonData}
        )
        const {
            data: {tuition_data, schedule_data, spf_data, opt_out_data, condition_text_data}
        } = careerResponse
        console.log("response by campus & program:", careerResponse)

        return {tuition_data, schedule_data, spf_data, opt_out_data, condition_text_data}
    }

    private async getSubmittedDocByUserId() {
        const submittedDocResponse = await this.gate.request(
            `/temporal/submitted/doc/${this.user.id}/get`
        )
        return submittedDocResponse
    }

    public async uploadDocument(file: File, id: string) {
        const IS_MULTI = false
        const formData = new FormData()
        formData.append(id, file)
        const data = await this.gate.request(
            `/uploads`,
            {
                data: formData
            },
            IS_MULTI
        )
        return data
    }

    public async signOut() {
        const json_data = this.document.json_data
        if (json_data.isSubmitted) {
            // json_data.disabilitySelected ||
            // delete json_data.disabilitySelected
            delete json_data.isSubmitted
            const id = this.user.doc.id
            await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })
        }
        await this.parentBridge.signOut()
    }

    public async getSignature(type: "signature" | "initials" | "parent"): Promise<object | null> {
        if (!this.currentSignature) {
            let r = await this.gate.request("/signatures/get-my", {data: {type: type}})
            if (r.success) {
                this.currentSignature = r.data
            } else {
                this.currentSignature = null
            }
        }

        return this.currentSignature
    }

    public addValidateFunction(f: Function) {
        for (let item of this.validateFunctions) {
            if (item === f) {
                return
            }
        }

        this.validateFunctions.push(f)
    }

    public removeValidateFunction(f: Function) {
        console.log("removeValidateFunction")
        // @ts-ignore
        console.log(f.functionId)

        console.log(
            this.validateFunctions.map((e) => {
                // @ts-ignore
                return e.functionId
            })
        )
        for (let i = 0; i < this.validateFunctions.length; i++) {
            // @ts-ignore
            if (this.validateFunctions[i].functionId === f.functionId) {
                this.validateFunctions.splice(i, 1)
                console.log(
                    this.validateFunctions.map((e) => {
                        // @ts-ignore
                        return e.functionId
                    })
                )
                return
            }
        }
    }

    public validate(): boolean {
        console.log("onValidate:", this.validateFunctions)
        let results = []
        for (let f of this.validateFunctions) {
            const r = f()
            results.push(r)
        }

        if (results.includes(false)) {
            return false
        }
        return true
    }

    public async loadVisitor() {
        let r = await this.gate.request("/users/get-me")
        this.visitor = r.data
    }

    public async loadAll(token: string, user_id: number) {
        if (!token || !user_id) {
            return
        }
        this.gate.setToken(token)
        await Promise.all([
            this.loadUser(user_id),
            // this.loadVisitor(),
            this.loadCareerInformation(),
            this.loadCampuses(),
            this.loadCampusesLinks()
        ]) // add a control to view if we have campus selected & program selected to refill tuition

        this.document = this.user.doc
        // this.documentClone = _.cloneDeep({...this.document})

        console.log("MODEL>LOAD ALL web")
        console.log(toJS(this.document))
        this.form_id =
            (this.document.json_data.progress as FormID) || "application_career_information"

        if (!this.document.json_data.role) {
            this.document.json_data.role = this.user.role
        }
        if (!this.document.json_data.first_name) {
            this.document.json_data.first_name = this.user.firstName
        }
        if (!this.document.json_data.last_name) {
            this.document.json_data.last_name = this.user.lastName
        }

        if (!this.document.json_data.home_phone) {
            if (this.user.phones.length > 0) {
                const {
                    phones: [{phone}]
                } = this.user
                this.document.json_data.home_phone = phone || ""
            } else {
                this.document.json_data.home_phone = ""
            }
        }

        if (!this.document.json_data.full_name) {
            if (this.user.middleName) {
                this.document.json_data.full_name = `${this.user.firstName} ${this.user.middleName} ${this.user.lastName}`
            } else {
                this.document.json_data.full_name = `${this.user.firstName} ${this.user.lastName}`
            }
        }
        if (!this.document.json_data.email) {
            if (this.user.emails.length > 0) {
                const {
                    emails: [{email}]
                } = this.user
                this.document.json_data.email = email || ""
            } else {
                this.document.json_data.email = ""
            }
        }
        if (!this.document.json_data.about_us) {
            this.document.json_data.about_us = "" // `${this.user.json_data.about_us}`
        }
        if (!this.document.json_data.about_us_text) {
            this.document.json_data.about_us_text = "" // `${this.user.json_data.about_us_text}`
        }

        this.dispatcher.dispatch()
        this.dispatcher2.dispatch()
    }

    public async deleteSupportedDocument(id: number) {
        const r = await this.gate.request(`/uploads/${id}/delete`)
        return r
    }

    private currentSignature
}

export interface SignatureVO {
    id
    user_id
    json_data: {
        img: string
    }
    ip // "172.17.0.1"
    created_at // "2019-10-17T11:22:43.823Z"
    updated_at // "2019-10-17T11:22:43.823Z"
    deleted_at
}

interface UserDocument {
    id?: number
    // pack_id
    // visitor_id
    // user_id
    // user: User
    // type
    json_data: JsonDocument
    // created_at
    // updated_at
    // deleted_at
}

export interface JsonDocument {
    [key: string]: string | any
}

export interface CareerDocument {
    [key: string]: any
}

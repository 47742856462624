import React, {Component} from "react"
import styles from "./CareerInformation.module.css"
import {Label} from "components/inputs/Label"
import {SelectOption} from "data/DataStore"
import {JsonDocument, Model, CareerDocument} from "../../Model"
import {Campus, Program, Advisor, CareerDate} from "data/interfaces"
import {observable, toJS} from "mobx"
import {Select} from "components/inputs/Select"
import {RadioButton} from "components/inputs/RadioButton"
import {LabelRadio} from "components/inputs/LabelRadio"
import {Input} from "components/inputs/input"
import {Disclosure} from "components/Disclosure"
import {findErrors} from "../../lib/functions/findErrors"
import {observer} from "mobx-react"
import {
    formatStandarUSDate,
    DELIMITER,
    getPartsByDelimiter,
    REFILL_FORMS_IDS,
    refreshSignaturesOrInitials,
    MANHATTAN
} from "helper/Methods"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const originalRules = {
    program: mandatory,
    starting_term: mandatory,
    campus: mandatory,
    advisor: mandatory
    // disabilites: mandatory
}
let rules = {
    ...originalRules
}

const parentRules = {
    has18: mandatory,
    parent_name: mandatory,
    parent_last_name: mandatory,
    parent_email: {
        ...mandatory,
        email: {
            email: true
        }
    }
}

@observer
export class CareerInformation extends Component<Props, {}> {
    private has18Key = "has18"
    private YES = "yes"
    private PARENT_KEY = {
        name: "parent_name",
        lastname: "parent_last_name",
        email: "parent_email"
    }

    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}
    private career_data: CareerDocument
    private career_campuses: any[] = []
    private career_programs: any[] = []
    private career_advisors: any[] = []
    private career_terms: any[] = []
    private CAREER_KEYS = ["campus", "program", "advisor", "year", "campuses", "starting_term"]
    private EXTRA_INFO = "extra"
    private STARTING_TERM = "starting_term"
    private START_DATE = "start_date"
    private MUA_TYPE = "mua_type"
    constructor(props) {
        super(props)
        console.log("Inside constructor CareerInformation")

        this.onChange = this.onChange.bind(this)
        this.onInitFillSelectOptions = this.onInitFillSelectOptions.bind(this)
        this.onModel = this.onModel.bind(this)
        this.json_data = props.model.document.json_data
        if (!this.json_data[this.EXTRA_INFO]) {
            this.initExtraInfo() // todo: careful with this.
        }
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    private findByKey(list, keyField) {
        const parts = getPartsByDelimiter(keyField)
        const [id] = parts
        return list.find(({id: keyId}) => keyId === +id)
    }
    private initExtraInfo() {
        const extra = this.EXTRA_INFO
        this.json_data[extra] = {}
    }

    private addExtraInfoCampusProgramYear() {
        const [campus, program, advisor, yearInfo, campusesInfo] = this.CAREER_KEYS
        const extra = this.EXTRA_INFO
        const fieldCampus = this.json_data[campus]
        const fieldProgram = this.json_data[program]
        const fieldStartingTerm = this.json_data[this.STARTING_TERM]
        const fieldAdvisor = this.json_data[advisor]
        if (fieldCampus && fieldProgram && fieldStartingTerm && fieldAdvisor) {
            const {campuses, year} = this.career_data
            const foundCampus = this.findByKey(campuses, fieldCampus)
            if (foundCampus) {
                const {programs, advisors} = foundCampus
                const foundProgram = this.findByKey(programs, fieldProgram)
                if (foundProgram) {
                    const {startingTerms} = foundProgram
                    const foundTerm = this.findByKey(startingTerms, fieldStartingTerm)
                    const foundAdvisor = this.findByKey(advisors, fieldAdvisor)
                    //todo: this is updating every time, fix the campus with campuses
                    const campusWithInfo = this.props.model.campusesInfo.findIndex(
                        ({id}) => foundCampus.id === id
                    )
                    const newCampusWithInfo =
                        campusWithInfo > -1 ? this.props.model.campusesInfo[campusWithInfo] : {}
                    this.json_data[extra][campus] = {...foundCampus, ...newCampusWithInfo}
                    this.json_data[extra][program] = foundProgram
                    this.json_data[extra][this.STARTING_TERM] = foundTerm
                    this.json_data[extra][advisor] = foundAdvisor
                    this.json_data[extra][yearInfo] = year
                    this.json_data[extra][campusesInfo] = this.props.model.campusesInfo
                    //todo: add links to a program
                    const links = this.addLinksToProgram(foundCampus.id, foundProgram.id)
                    this.json_data[extra][program].links = links
                }
            }
        }
    }

    private addLinksToProgram(campusId, programId): any[] {
        const campusIndexFound = this.props.model.campusesLinksInfo.findIndex(
            ({id}) => id === campusId
        )
        if (campusIndexFound > -1) {
            const campus = this.props.model.campusesLinksInfo[campusIndexFound]
            // console.log("@@@@ campus:", toJS(campus))
            const {programs} = campus
            const programIndexFound = programs.findIndex(({id}) => id === programId)
            if (programIndexFound > -1) {
                const program = programs[programIndexFound]
                // console.log("@@@@ program:", toJS(program))
                return program.links
            }
            return []
        }
        return []
    }

    public onInitFillSelectOptions() {
        const [campus, program, advisor] = this.CAREER_KEYS
        this.career_data = this.props.model.careerInfo
        if (this.career_data) {
            // console.log('this.career_data:', toJS( this.career_data));
            this.career_campuses = this.fillSelectOption(this.career_data.campuses)
            if (this.json_data[campus]) {
                const [id, name, code] = getPartsByDelimiter(this.json_data[campus])
                const campusFound = this.career_data.campuses.find(
                    ({id: campusId, name: campusName, code: campusCode}) =>
                        +id === campusId && code === campusCode && name === campusName
                )
                // console.log('campus found:', toJS(campusFound), toJS(this.props.model.document.json_data))

                if (campusFound) {
                    this.career_programs = this.fillSelectOption(campusFound.programs)
                    if (this.json_data[program]) {
                        const [id, name, code] = getPartsByDelimiter(this.json_data[program])
                        const programFound = campusFound.programs.find(
                            ({id: programId, name: programName, code: programCode}) =>
                                +id === programId && code === programCode && name === programName
                        )
                        if (programFound) {
                            this.career_terms = this.fillStartingTerms(programFound.startingTerms)
                            if (this.json_data[this.STARTING_TERM]) {
                                const [aTermId, aTermName, aTermCode] = getPartsByDelimiter(
                                    this.json_data[this.STARTING_TERM]
                                )

                                const termFound = programFound.startingTerms.find(
                                    ({
                                        id: startingTermId,
                                        name: startingTermName,
                                        code: startingTermCode
                                    }) =>
                                        +aTermId === startingTermId &&
                                        aTermName === startingTermName &&
                                        aTermCode === startingTermCode
                                )
                                if (termFound) {
                                    this.career_advisors = this.fillAdivisors(campusFound.advisors)
                                    this.addExtraInfoCampusProgramYear()
                                } else {
                                    this.career_advisors = []
                                    this.json_data[advisor] = ""
                                }
                            } else {
                                this.career_advisors = []
                                this.json_data[advisor] = ""
                            }
                        } else {
                            this.career_terms = []
                            this.career_advisors = []
                            this.json_data[this.STARTING_TERM] = ""
                            this.json_data[advisor] = ""
                        }
                    } else {
                        this.career_terms = []
                        this.career_advisors = []
                        this.json_data[this.STARTING_TERM] = ""
                        this.json_data[advisor] = ""
                    }
                } else {
                    this.career_programs = []
                    this.career_terms = []
                    this.career_advisors = []
                    this.json_data[program] = ""
                    this.json_data[this.STARTING_TERM] = ""
                    this.json_data[advisor] = ""
                }
            } else {
                this.career_programs = []
                this.career_terms = []
                this.career_advisors = []
                this.json_data[program] = ""
                this.json_data[this.STARTING_TERM] = ""
                this.json_data[advisor] = ""
            }
        }
    }

    public async componentDidUpdate() {
        this.onInitFillSelectOptions()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
        this.onInitFillSelectOptions()
        this.forceUpdate()
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        const [manhattanCampusId] = getPartsByDelimiter(this.json_data.campus)
        if (MANHATTAN.id === +manhattanCampusId) {
            if (
                !this.json_data[this.has18Key] ||
                (this.json_data[this.has18Key] && this.json_data[this.has18Key] !== this.YES)
            ) {
                rules = {...rules, ...parentRules}
            } else {
                delete rules[this.has18Key]
                delete rules[this.PARENT_KEY.email]
                delete rules[this.PARENT_KEY.name]
                delete rules[this.PARENT_KEY.lastname]
            }
        } else {
            delete rules[this.has18Key]
            delete rules[this.PARENT_KEY.email]
            delete rules[this.PARENT_KEY.name]
            this.json_data[this.PARENT_KEY.lastname] = ""
        }
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private fillStartingTerms(list): SelectOption[] {
        return list.map((el: CareerDate) => {
            const formattedDate = formatStandarUSDate(el.startDate)
            return {
                id: el.id,
                value: `${el.id}${DELIMITER}${el.name}${DELIMITER}${el.code}${DELIMITER}${el.startDate}`,
                label: `${el.name} - ${formattedDate}`
            }
        })
    }

    private fillSelectOption(list): SelectOption[] {
        return list.map((el: Campus | Program) => {
            return {
                id: el.id,
                value: `${el.id}${DELIMITER}${el.name}${DELIMITER}${el.code}`,
                label: el.name
            }
        })
    }

    private fillAdivisors(list): SelectOption[] {
        return list.map((el: Advisor) => {
            return {
                id: el.id,
                value: `${el.id}${DELIMITER}${el.email}`,
                label: `${el.firstName} ${el.lastName} (${el.email})`
            }
        })
    }

    private onChange(id: string, value, type?: string) {
        if (this.json_data.isSubmitted) {
            delete this.json_data.isSubmitted
        }
        if (id === "disabilites") {
            delete this.json_data.disabilitySelected
            delete this.json_data.disabilitySubmitted
        }
        this.json_data[id] = value
        console.log("onChange CareerInformation:", {id, value})
        const [campus, program, advisor] = this.CAREER_KEYS
        if (id === campus) {
            this.json_data[program] = ""
            this.json_data[this.STARTING_TERM] = ""
            this.json_data[advisor] = ""
            this.initExtraInfo()
            delete this.json_data[this.PARENT_KEY.email]
            delete this.json_data[this.PARENT_KEY.name]
            delete this.json_data[this.PARENT_KEY.lastname]
            delete this.json_data[this.has18Key]
        }
        if (id === program) {
            this.json_data[this.STARTING_TERM] = ""
            this.json_data[advisor] = ""
        }
        if (id === this.STARTING_TERM) {
            this.json_data[advisor] = ""
        }
        if (id === this.has18Key) {
            this.json_data[this.PARENT_KEY.email] = ""
            this.json_data[this.PARENT_KEY.name] = ""
            this.json_data[this.PARENT_KEY.lastname] = ""
        }
        this.onInitFillSelectOptions()
        if (id === campus || id === program) {
            this.removeIdsFromDocument(this.json_data)
        }
        if (id === this.STARTING_TERM) {
            this.removeIdsFromEnrollmentAgreement(this.json_data)
        }
        this.props.model.dispatcher2.dispatch()
        this.forceUpdate()
    }

    private removeIdsFromEnrollmentAgreement(json_data) {
        refreshSignaturesOrInitials(
            json_data,
            [
                // ...REFILL_FORMS_IDS.opt_outs.forms,
                // ...REFILL_FORMS_IDS.enrollment_agreement.forms
            ],
            [...REFILL_FORMS_IDS.opt_outs.ids, ...REFILL_FORMS_IDS.enrollment_agreement.ids]
        )
    }

    private removeIdsFromDocument(json_data) {
        refreshSignaturesOrInitials(
            json_data,
            [
                ...REFILL_FORMS_IDS.enrollment_application.forms,
                // ...REFILL_FORMS_IDS.opt_outs.forms,
                // ...REFILL_FORMS_IDS.enrollment_agreement.forms,
                ...REFILL_FORMS_IDS.arbitration_agreement.forms,
                ...REFILL_FORMS_IDS.school_performance_fact.forms,
                ...REFILL_FORMS_IDS.distance_education_disclosure.forms,
                ...REFILL_FORMS_IDS.freelance_selfemployed_disclosure.forms,
                ...REFILL_FORMS_IDS.checklist_page.forms,
                ...REFILL_FORMS_IDS.academic_adjustment_form.forms,
                ...REFILL_FORMS_IDS.criminal_disclosure.forms,
                ...REFILL_FORMS_IDS.application_upload_docs.forms,
                ...REFILL_FORMS_IDS.ferpa_release_form.forms,
                ...REFILL_FORMS_IDS.overtime_disclosure.forms
            ],
            [
                ...REFILL_FORMS_IDS.enrollment_application.ids,
                REFILL_FORMS_IDS.enrollment_application.conditionId,
                ...REFILL_FORMS_IDS.opt_outs.ids,
                ...REFILL_FORMS_IDS.enrollment_agreement.ids,
                ...REFILL_FORMS_IDS.arbitration_agreement.ids,
                ...REFILL_FORMS_IDS.school_performance_fact.ids,
                ...REFILL_FORMS_IDS.distance_education_disclosure.ids,
                ...REFILL_FORMS_IDS.freelance_selfemployed_disclosure.ids,
                ...REFILL_FORMS_IDS.checklist_page.ids,
                ...REFILL_FORMS_IDS.academic_adjustment_form.ids,
                ...REFILL_FORMS_IDS.criminal_disclosure.ids,
                ...REFILL_FORMS_IDS.application_upload_docs.ids,
                ...REFILL_FORMS_IDS.ferpa_release_form.ids,
                ...REFILL_FORMS_IDS.overtime_disclosure.ids
            ]
        )
    }

    private hideParentInformation = (json_data) => {
        if (!json_data) {
            return false
        }
        if (!json_data.campus) {
            return false
        }
        const {campus} = json_data
        // console.log('@@@ currentCampus:', campus)
        const [manhattanCampusId] = getPartsByDelimiter(campus)
        if (MANHATTAN.id === +manhattanCampusId) {
            return true
        }
        return false
    }

    public render() {
        const model = this.props.model
        const {career_campuses, career_programs, career_advisors, career_terms, json_data} = this
        const p = {model, onChange: this.onChange, errors: this.errors}
        const r = {onChange: this.onChange, json_data: this.json_data, errors: this.errors}

        return (
            <React.Fragment>
                {json_data.role ? !json_data.accepted && <Disclosure model={model} /> : null}
                <div className={styles.root}>
                    <h2 className={styles.h2}>CAREER INFORMATION</h2>
                    <div className={styles.form}>
                        <Label text="Campus" required>
                            <Select id="campus" options={career_campuses} {...p} />
                        </Label>

                        <Label text="Program" required>
                            <Select id="program" options={career_programs} {...p} />
                        </Label>

                        <Label text="Starting term" required>
                            <Select id="starting_term" options={career_terms} {...p} />
                        </Label>

                        <Label text="Advisor" required>
                            <Select id="advisor" options={career_advisors} {...p} />
                        </Label>
                        {this.hideParentInformation(json_data) && (
                            <LabelRadio
                                text="Are you 18 or older?"
                                error={this.errors[this.has18Key]}
                                className={styles.ageOf18}
                                required>
                                <RadioButton
                                    label="Yes"
                                    value="yes"
                                    group_name={this.has18Key}
                                    {...r}
                                />
                                <RadioButton
                                    label="No"
                                    value="no"
                                    group_name={this.has18Key}
                                    {...r}
                                />
                            </LabelRadio>
                        )}
                    </div>
                    {this.hideParentInformation(json_data) &&
                        json_data[this.has18Key] &&
                        json_data[this.has18Key] !== this.YES && (
                            <Label text="">
                                <div className={styles.parentOptions}>
                                    <h5 className={styles.parentTitle}>Parent Information</h5>
                                    <Label text="First Name:" className={styles.gap} required>
                                        <Input id="parent_name" {...r} />
                                    </Label>
                                    <Label text="Last Name:" className={styles.gap} required>
                                        <Input id="parent_last_name" {...r} />
                                    </Label>
                                    <Label text="Email" className={styles.gap} required>
                                        <Input id="parent_email" {...r} />
                                    </Label>
                                </div>
                            </Label>
                        )}
                    <p className={styles.text}>
                        <span>*</span> Indicates that a response is required
                    </p>
                    {/* <h2 className={styles.subtitle}>STUDENTS WITH DISABILITIES</h2>
                    <p className={styles.disabilitesText}>
                        Bellus Academy does not discriminate on the basis of disability in admission
                        or access to its programs, services, or activities of individuals who meet
                        essential eligibility requirements. If you are admitted to Bellus Academy,
                        and you require reasonable accommodations for a documented disability,
                        please contact the Academy Director of the campus you want to attend in
                        order to create an academic plan to meet your needs.
                    </p>
                    <p className={styles.help}>
                        <span>*</span> Do you require special accommodation to attend Bellus
                        Academy? By clicking "Yes", an Admission Advisor will reach out and assist
                        you in completing the enrollment process.
                    </p> */}
                    {/* disabled={model.document.json_data.disabilitySelected} */}
                    {/* <div className={styles.disbalitiesOption}>
                        <fieldset className={styles.noBorder}>
                            <LabelRadio
                                text=""
                                required
                                error={this.errors["disabilites"]}
                                className={styles.labelRadio}>
                                <>
                                    <RadioButton
                                        label="Yes"
                                        value="yes"
                                        group_name="disabilites"
                                        {...r}
                                    />
                                    <RadioButton
                                        label="No"
                                        value="no"
                                        group_name="disabilites"
                                        {...r}
                                    />
                                </>
                            </LabelRadio>
                        </fieldset>
                    </div> */}
                </div>
            </React.Fragment>
        )
    }
}
